import React from "react";

function About() {
    const styles = {
        container: {
            background: "linear-gradient(to bottom, #00aaff, #003366)",
            color: "white",
            padding: "50px 20px",
            minHeight: "100vh",
        },
        sectionTitle: {
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "white",
        },
        paragraph: {
            maxWidth: "800px",
            margin: "20px auto",
            fontSize: "18px",
            lineHeight: "1.6",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "10px",
        },
        missionVision: {
            display: "flex",
            justifyContent: "space-around",
            marginTop: "50px",
            flexWrap: "wrap",
        },
        mission: {
            flex: "1 1 100%",
            backgroundColor: "#003366",
            padding: "30px",
            margin: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        },
        vision: {
            flex: "1 1 100%",
            backgroundColor: "#003366",
            padding: "30px",
            margin: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        },
        ciitsTextContainer: {
            backgroundColor: "#ffcc00",
            padding: "20px",
            textAlign: "center",
            borderRadius: "10px",
            maxWidth: "600px",
            margin: "20px auto",
        },
        ciitsText: {
            fontSize: "40px",
            fontWeight: "bold",
            color: "white",
            letterSpacing: "2px",
        },
        teamSection: {
            textAlign: "center",
            marginTop: "50px",
        },
        teamMember: {
            display: "inline-block",
            margin: "20px",
            width: "250px",
            backgroundColor: "#00568f",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
        teamImage: {
            borderRadius: "50%",
            width: "120px",
            height: "120px",
            objectFit: "cover",
            marginBottom: "10px",
        },
        teamName: {
            fontWeight: "bold",
            fontSize: "18px",
            color: "white",
        },
        teamRole: {
            fontStyle: "italic",
            color: "#f1f1f1",
        },
        // Mobile media query
        "@media (max-width: 768px)": {
            sectionTitle: {
                fontSize: "28px",
            },
            paragraph: {
                fontSize: "16px",
                padding: "15px",
            },
            missionVision: {
                flexDirection: "column",
                alignItems: "center",
            },
            mission: {
                padding: "20px",
            },
            vision: {
                padding: "20px",
            },
            teamMember: {
                width: "200px",
            },
            ciitsText: {
                fontSize: "30px",
            },
        },
    };

    return (
        <div style={styles.container}>
            <section>
                <h1 style={styles.sectionTitle}>About Us</h1>
                <p style={styles.paragraph}>
                    At CIITS (Central India IT Services), we are committed to delivering top-notch IT services
                    that help businesses transform and thrive in a digital-first world. Our expert team provides
                    innovative solutions with a focus on customer satisfaction and technological excellence.
                </p>
            </section>

            <section style={styles.missionVision}>
                <div style={styles.mission}>
                    <h3 style={styles.sectionTitle}>Our Mission</h3>
                    <p style={styles.paragraph}>
                        Our mission is to empower businesses through advanced IT solutions that are both innovative
                        and efficient. We aim to help businesses automate processes, enhance their digital presence,
                        and drive growth with our tailored services.
                    </p>
                </div>
                <div style={styles.vision}>
                    <h3 style={styles.sectionTitle}>Our Vision</h3>
                    <p style={styles.paragraph}>
                        Our vision is to become the leading provider of IT services in India by offering innovative
                        and impactful solutions that cater to businesses of all sizes. We aspire to foster a
                        culture of growth, collaboration, and excellence.
                    </p>
                </div>
            </section>

            <section style={styles.teamSection}>
                <h2 style={styles.sectionTitle}>Meet Our Team</h2>
                <div>
                    <div style={styles.teamMember}>
                        <img
                            src="https://via.placeholder.com/120"
                            alt="Team Member 1"
                            style={styles.teamImage}
                        />
                        <p style={styles.teamName}>Nikhil Dubey</p>
                        <p style={styles.teamRole}>CEO & Founder</p>
                    </div>
                    <div style={styles.teamMember}>
                        <img
                            src="https://via.placeholder.com/120"
                            alt="Team Member 2"
                            style={styles.teamImage}
                        />
                        <p style={styles.teamName}>Shashikant Singh Dubey</p>
                        <p style={styles.teamRole}>CFO & Co-Founder</p>
                    </div>
                    <div style={styles.teamMember}>
                        <img
                            src="https://via.placeholder.com/120"
                            alt="Team Member 3"
                            style={styles.teamImage}
                        />
                        <p style={styles.teamName}>Prashant Dubey</p>
                        <p style={styles.teamRole}>Project Manager</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
